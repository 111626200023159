<template>
    <v-card class="mx-auto">
        <v-toolbar color="deep-blue accent-4" cards dark flat>
            <v-card-title class="text-h6 font-weight-regular">
                Add annotation
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeAnnotation()">
                <svg-icon type="mdi" :path="mdiClose"></svg-icon>
            </v-btn>
        </v-toolbar>
        <v-form ref="form" class="pa-4 pt-6" @submit.prevent>
            <v-text-field :value="inputTime" filled color="deep-purple" label="Date Time" type="datetime-local"
                :rules="formRules.dateRules"></v-text-field>
            <v-textarea v-model="annotat" solo label="Annotation content" clearable auto-gro counter
                :rules="formRules.annotationRules" required></v-textarea>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" class="white--text" color="primary" depressed @click="updateAnnotation()">
                update
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>


import SvgIcon from '@jamescoyle/vue-icon'
import { mdiClose, mdiLock } from '@mdi/js';


export default {
    components: {
        SvgIcon
    },
    props: {
        inputTime: {
            type: String,
            default: ""
        },
        HN: String,
        AnyAnnotat: String,
        mdiLock:mdiLock
        

    },
    data() {
        return {
            mdiClose: mdiClose,
            maxCharacter: [v => v.length <= 100 || 'Max 100 characters'],
            formRules: {
                dateRules: [
                    value => {
                        if (value) return true
                        return 'You must enter date.'
                    },
                ],
                annotationRules: [
                    value => {
                        if (value) return true
                        return 'You must enter annotation.'
                    },
                ]
            },
            annotat: "",

            formData: {
                annotationContent: "",
                time: ""
            }
        }
    },
    methods: {
        updateAnnotation() {
            if (this.$refs.form.validate()) {
                this.formData.time = this.inputTime,
                    this.formData.annotationContent = this.annotat
                this.$emit('data', this.formData)
                this.$emit('close', false)
                /* this.annotat = "" */
                this.$refs.form.resetValidation()
            }
        },
        closeAnnotation() {
            this.$emit('close', false)
            /* this.annotat = "" */
            this.$refs.form.resetValidation()
        }
    },
    watch: {
        AnyAnnotat(nw) {
            this.annotat = nw
        },
        inputTime(nt, ot) {
            if (nt != ot) {
                this.annotat = ""
                this.$refs.form.resetValidation()
            }
        }
    }
};


</script>