<template>
  <v-card dark class="pt-7">
    <v-toolbar class="pa-2   rounded-lg">
      <v-toolbar-title>
        <v-btn router to="/dashboard">
          <SvgIcon type="mdi" :path="mdiKeyboardReturn"></SvgIcon>
        </v-btn>
        Charting graph
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field label="Date" @change="getDashdata()" v-model="selectDate" class="mx-3" type="date" max-width="20vw" />
      <v-select v-model="selectInterval" @change="getDashdata()" :items="intervals" label="Interval" class="mx-3"
        max-width="20vw" return-object></v-select>
      <v-checkbox v-model="autoUpdate" class="mx-3" label="auto refresh" color="red" v-if="autoUpdateFuncShow"
        :on-icon="autoIcon.on" :off-icon="autoIcon.off"></v-checkbox>
      <v-btn @click="getDashdata" color="indigo" class="text-white" dark>Refresh</v-btn>
    </v-toolbar>

    <v-app-bar class="px-2 ma-1 rounded-lg" dense>
      <span class="mx-6">Device ID: {{ HNcase }}</span>
      <v-spacer></v-spacer>
      <!-- <v-btn color="red" dark @click.stop="openAlertDialog">
        <SvgIcon type="mdi" :path="alarmIcon"></SvgIcon>
        Alert Level
      </v-btn> -->
    </v-app-bar>

    <v-card-title>
      Tabular trend
      <v-spacer></v-spacer>
      <v-btn color="" :disabled="selectedExportVS.length == 0" @click="exportVSdata()">
        <v-progress-circular v-if="exporting" indeterminate color="white"></v-progress-circular>
        <h3 v-else>Export</h3>
      </v-btn>
    </v-card-title>
    <v-data-table dense :headers="dataTableHeaders" :items="dataTableItems" item-key="TS" class="elevation-1" show-select
      v-model="selectedExportVS" :items-per-page="15" :loading="loading">

      <template v-slot:[`item.actions`]="{ index }">
        <span>
          <v-btn @click="showQRcodeFunc(dataTableItems[index])" small color="grey darken-3" dark class="mx-2"
            :disabled="dataTableItems[index].bedID.substring(0, dataTableItems[index].bedID.indexOf(' /')) == 'No entry'">
            <SvgIcon type="mdi" :path="QRicon"></SvgIcon>
          </v-btn>
        </span>
        <!--         <span><v-btn small @click="addAnnoation({ tblt: dataTableItems[index].TS })"
            :color="seeIncludeOrNot(dataTableItems[index].TS)" class="mx-2">
            <SvgIcon type="mdi" :path="annotationIcon"></SvgIcon>
          </v-btn></span> -->
      </template>
    </v-data-table>

    <Plotly id="chart" :data="dataVitalSign" :layout="layout" :display-mode-bar="true" :displaylogo="false"
      @dblclick="addAnnoation" @clickannotation="addAnnoation" :responsive="true" />

    <v-dialog v-model="showQRcode" min-width="700px" max-width="950px">
      <QRVSComponent :vitalSign="showQRvalue" @closeQR="closeQR"></QRVSComponent>
    </v-dialog>

    <v-dialog v-model="alertDrawer" inset max-width="700px">
      <alertContent :alertItems="alertItem" @updateAlertItem="updateAlert" @close="closeAlert" />
    </v-dialog>

    <!--     :alertItems="alertItem" @updateAnnotation="updateAlert" -->

    <v-dialog v-model="annotationDrawer" inset max-width="700px">
      <annotationContent :AnyAnnotat="initAnnotationContent" :HN="HNcase" :inputTime="annotationTime"
        @close="closeAnnotation" @data="updateAnnotation" />
    </v-dialog>

    <SnackBarCard :message="snackbarStart.message" :color="snackbarStart.color" :snackBarStatue="snackbarStart.open" />

  </v-card>
</template>

<script>
import store from '@/vuex/store'
import { Plotly } from 'vue-plotly'
import CryptoJS from "crypto-js"
import Vue from 'vue';
const axios = require('axios');
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '7sec' })
import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiKeyboardReturn, mdiAccountInjury, mdiCheckCircleOutline, mdiCircleOutline, mdiQrcode, mdiAlarmLightOutline, mdiNote } from '@mdi/js';

import QRVSComponent from '@/components/QRVSComponent.vue';
import alertContent from "../components/alertContent.vue"
import annotationContent from "../components/annotationContent.vue"
import SnackBarCard from '@/components/snackBarCard.vue'


import { returnNoEntryToEmpty } from '../assets/view.js'

export default {
  components: {
    Plotly,
    SvgIcon,
    alertContent,
    annotationContent,
    QRVSComponent,
    SnackBarCard
  },
  props: {
    MAC: {
      type: String,
    },
    HN: {
      type: String,
    },
    BED: {
      type: String
    },
    DATE: {
      type: String
    }
  },

  data() {
    return {
      latestResult: "",
      timer: null,
      isMobile: 3,
      loading: false,
      mdiKeyboardReturn: mdiKeyboardReturn,
      autoUpdate: false,
      autoUpdateFuncShow: false,
      autoIcon: {
        on: mdiCheckCircleOutline,
        off: mdiCircleOutline
      },

      QRicon: mdiQrcode,
      alarmIcon: mdiAlarmLightOutline,
      alertDrawer: null,
      alertItem: [],

      annotationDrawer: null,
      annotationTime: "",
      annotationContent: "",
      annotationGraph: [],
      annotationIcon: mdiNote,
      initAnnotationContent: "",

      selectDate: "",
      selectInterval: this.$cookies.isKey("setting") ? this.$cookies.get("setting").chartingInterval : { text: '5min', value: 300 },
      intervals: [
        { text: '1min', value: 60 },
        { text: '5min', value: 300 },
        { text: '10min', value: 600 },
        { text: '30min', value: 1800 },
        { text: '1 hrs', value: 3600 },
        { text: '2 hrs', value: 7200 },
        { text: '4 hrs', value: 14400 },
        { text: '8 hrs', value: 28800 },
        { text: '12 hrs', value: 43200 },
        { text: '24 hrs', value: 86400 },
        { text: 'by nibp', value: "nibp" },
        /* { text: 'by EWS scoring', value: "scoring" }, */
        { text: 'All', value: 1 }
      ],
      starttime: '',

      bed: "",
      name: "",
      HNcase: "",

      iconPath: mdiAccountInjury,

      showQRcode: false,
      showQRvalue: {},
      QRboxValue: {
        QRcodeValue: "",
        HNQRcode: "",
        vitalSignValue: {}
      },

      dataTableHeaders: [
        {
          text: 'Time',
          align: 'start',
          value: 'TS',
          sortable: false
        },
        { text: 'DeviceName/Patient ID', value: 'bedID', sortable: false },
        { text: 'NIBP | PR', value: 'nibp', sortable: false, width: '185px' },
        { text: 'SPO2', value: 'spo2', sortable: false, width: '100px' },
        { text: 'Temp', value: 'temp', sortable: false },
        { text: 'Resp', value: 'rr', sortable: false },
        { text: 'ECGHR', value: 'hr', sortable: false },
        { text: 'EtCO2', value: 'etco2', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dataTableItems: [],
      selectedExportVS: [],
      exporting:false,

      dataVitalSign: [
        {
          x: [],
          y: [],
          mode: "text",
          text: '˄',
          name: 'Systolic pressure',
          hovertemplate: '%{y:f} mmHg',
          textfont: {
            color: []
          },
          marker: {
            color: 'rgb(255, 217, 102)',
            size: 12
          },
          type: 'scatter',
          xaxis: "x5", yaxis: "y5"
        },
        {
          x: [],
          y: [],
          mode: 'text',
          text: 'x',
          name: 'nibppr',
          hovertemplate: '%{y:f} bpm',
          textfont: {
            color: []//use this as alarm setting
          },
          marker: {
            color: 'rgb(255, 217, 102)',
            size: 12
          },
          type: 'scatter',
          xaxis: "x5", yaxis: "y5"
        },
        {
          x: [],
          y: [],
          mode: 'text',
          text: 'v',
          name: 'Diastolic pressure',
          hovertemplate: '%{y:f} mmHg',
          textfont: {
            color: []//use this as alarm setting
          },
          marker: {
            color: 'rgb(255, 217, 102)',
            size: 12
          },
          type: 'scatter',
          xaxis: "x5", yaxis: "y5"
        },
        {
          x: [],
          y: [],
          mode: 'markers',
          name: 'Respiration rate',
          text: 'Respiration rate',
          marker: {
            color: 'rgb(164, 194, 244)',
            size: 10,
            line: {
              color: [],
              width: []
            },
          },
          hovertemplate: '%{y:f} rpm',
          type: 'scatter',
          xaxis: "x3", yaxis: "y3"
        },
        {
          x: [],
          y: [],
          mode: 'markers',
          name: 'SPO2 PR',
          text: 'SPO2 PR',
          marker: {
            color: 'rgb(128, 134, 213)',
            size: 10,
            line: {
              color: [],
              width: []
            }
          },
          hovertemplate: '%{y:f} mmHg',
          type: 'scatter',
          xaxis: "x4", yaxis: "y4"
        },
        {
          x: [],
          y: [],
          mode: 'markers',
          name: 'Oxygen saturation',
          text: 'Oxygen saturation',
          marker: {
            color: 'rgb(0, 194, 244)',
            size: 10,
            line: {
              color: [],
              width: []
            }
          },
          hovertemplate: '%{y:f} rpm',
          type: 'scatter',
          xaxis: "x4", yaxis: "y4"
        },
        {
          x: [],
          y: [],
          mode: 'markers',
          name: 'temperature',
          text: 'temperature',
          marker: {
            color: 'rgb(255, 165, 0)',
            size: 10,
            line: {
              color: [],
              width: []
            }
          },
          hovertemplate: '%{y:.1f} degree',
          type: 'scatter',
          xaxis: "x2", yaxis: "y2"
        },
        {
          x: [],
          y: [],
          mode: 'markers',
          name: 'etco2',
          text: 'etco2',
          marker: {
            color: 'rgb(204,204,0)',
            size: 10,
            line: {
              color: [],
              width: []
            }
          },
          hovertemplate: '%{y:.1f} mmHg',
          type: 'scatter',
          xaxis: "x3", yaxis: "y3"
        },
        {
          x: [],
          y: [],
          mode: 'markers',
          name: 'ecghr',
          text: 'ecghr',
          marker: {
            color: 'green',
            size: 10,
            line: {
              color: [],
              width: []
            }
          },
          hovertemplate: '%{y:.1f} mmHg',
          type: 'scatter',
          xaxis: "x3", yaxis: "y3"
        },
        {
          x: [],
          y: [],
          name: "",
          marker: {
            color: 'rgba(255, 255, 255, 0)',
          },
          mode: 'markers',
          type: 'scatter',
          xaxis: "x", yaxis: "y"
        }
      ],
      config: {
        responsive: true,
        displaylogo: false
      },
      layout: {
        plot_bgcolor: "rgb(226, 223, 190)",
        paper_bgcolor: "rgb(226, 223, 190)",
        title: 'Monitor Record',
        showlegend: true,
        dragmode: 'pan',
        showgrid: true,
        xaxis: {
          autorange: false,
          title: 'time',
          range: [],
        },
        xaxis2: {
          gridwidth: 0.2,
          anchor: 'x2',
          matches: 'x'
        },
        xaxis3: {
          gridwidth: 0.2,
          anchor: 'x3',
          matches: 'x'
        },
        xaxis4: {
          gridwidth: 0.2,
          anchor: 'x4',
          matches: 'x'
        },
        xaxis5: {
          gridwidth: 0.2,
          anchor: 'x5',
          matches: 'x'
        },

        yaxis: {
          domain: [0, 0.1],
          title: 'annotation',
          visible: false,
        },
        yaxis2: {
          domain: [0.75, 1],
          anchor: 'y2',
          title: "Temperature",
          range: [32, 40]
        },
        yaxis3: {
          domain: [0.35, 0.5],
          anchor: 'y3',
          title: "other parameter",
        },
        yaxis4: {
          domain: [0.55, 0.7],
          anchor: 'y4',
          title: "SPO2/PR",
          range: [0, 100]
        },
        yaxis5: {
          domain: [0.12, 0.35],
          title: 'nibp/ibp',
          range: [0, 200],
          anchor: 'y5',
        },
        annotations: [
        ],
        //computed value can't use in data
        width: store.getters.getDevice == "Win32" || store.getters.getDevice == "Win64" ? document.documentElement.clientWidth * 1 : document.documentElement.clientWidth * 1,
        height: document.documentElement.clientHeight * 1.7,
      },

      demoData: [
        {
          "MessageID": 12372,
          "bed": "DEMO",
          "department": "TMH-AED",
          "facility": null,
          "VisitNum": null,
          "MACAddr": "66-39-30-31-39-3E",
          "localIP": null,
          "MachineID": "66-39-30-31-39-3E",
          "PID": "AE1000000",
          "LastName": "Zhang",
          "FirstName": "HY,",
          "Gender": "F",
          "Height": "",
          "Weight": "",
          "DOBstr": null,
          "DOB": null,
          "TS": "2023-12-14 11:00:17",
          "PDSTS": null,
          "sys": "111",
          "dia": "67",
          "mean": "82",
          "nibppr": "73",
          "IBPsys": "101",
          "IBPdia": "84",
          "IBPpr": "68",
          "spo2": "87",
          "spo2pr": "82",
          "temp": "36.0",
          "temp2": "35.0",
          "hr": "90",
          "rr": "10",
          "etco2": "29",
          "EWS_SCORE_RR": "",
          "EWS_SCORE_TEMP": "",
          "EWS_SCORE_BP_SYS": "",
          "EWS_SCORE_HR": "",
          "EWS_SCORE_LOC_AVPU": "",
          "EWS_SCORE_TOTAL": "7",
          "EWS_SCORE_CLASS_TEMP": "",
          "EWS_SCORE_CLASS_BP_SYS": "",
          "EWS_SCORE_CLASS_HR": "",
          "EWS_SCORE_CLASS_LOC_AVPU": "",
          "EWS_SCORE_CLASS_RR": "",
          "EWS_SYSTEM_NAME": "NEWS",
          "SpotCheck": 0,
          "ClinicianID": ""
        },
        {
          "MessageID": 11781,
          "bed": "DEMO",
          "department": "TMH-AED",
          "facility": null,
          "VisitNum": null,
          "MACAddr": "66-39-30-31-39-3E",
          "localIP": null,
          "MachineID": "66-39-30-31-39-3E",
          "PID": "AE1000000",
          "LastName": "Zhang",
          "FirstName": "HY,",
          "Gender": "F",
          "Height": "",
          "Weight": "",
          "DOBstr": null,
          "DOB": null,
          "TS": "2023-12-14 10:00:51",
          "PDSTS": null,
          "sys": "134",
          "dia": "79",
          "mean": "87",
          "nibppr": "75",
          "IBPsys": "140",
          "IBPdia": "93",
          "IBPpr": "83",
          "spo2": "88",
          "spo2pr": "86",
          "temp": "37.0",
          "temp2": "35.0",
          "hr": "92",
          "rr": "15",
          "etco2": "28",
          "EWS_SCORE_RR": "",
          "EWS_SCORE_TEMP": "",
          "EWS_SCORE_BP_SYS": "",
          "EWS_SCORE_HR": "",
          "EWS_SCORE_LOC_AVPU": "",
          "EWS_SCORE_TOTAL": "8",
          "EWS_SCORE_CLASS_TEMP": "",
          "EWS_SCORE_CLASS_BP_SYS": "",
          "EWS_SCORE_CLASS_HR": "",
          "EWS_SCORE_CLASS_LOC_AVPU": "",
          "EWS_SCORE_CLASS_RR": "",
          "EWS_SYSTEM_NAME": "NEWS",
          "SpotCheck": 0,
          "ClinicianID": ""
        },
        {
          "MessageID": 11712,
          "bed": "DEMO",
          "department": "TMH-AED",
          "facility": null,
          "VisitNum": null,
          "MACAddr": "66-39-30-31-39-3E",
          "localIP": null,
          "MachineID": "66-39-30-31-39-3E",
          "PID": "AE1000000",
          "LastName": "Zhang",
          "FirstName": "HY,",
          "Gender": "F",
          "Height": "",
          "Weight": "",
          "DOBstr": null,
          "DOB": null,
          "TS": "2023-12-14 09:53:23",
          "PDSTS": null,
          "sys": "104",
          "dia": "67",
          "mean": "95",
          "nibppr": "89",
          "IBPsys": "116",
          "IBPdia": "85",
          "IBPpr": "69",
          "spo2": "88",
          "spo2pr": "71",
          "temp": "35.0",
          "temp2": "36.0",
          "hr": "85",
          "rr": "10",
          "etco2": "23",
          "EWS_SCORE_RR": "",
          "EWS_SCORE_TEMP": "",
          "EWS_SCORE_BP_SYS": "",
          "EWS_SCORE_HR": "",
          "EWS_SCORE_LOC_AVPU": "",
          "EWS_SCORE_TOTAL": "6",
          "EWS_SCORE_CLASS_TEMP": "",
          "EWS_SCORE_CLASS_BP_SYS": "",
          "EWS_SCORE_CLASS_HR": "",
          "EWS_SCORE_CLASS_LOC_AVPU": "",
          "EWS_SCORE_CLASS_RR": "",
          "EWS_SYSTEM_NAME": "NEWS",
          "SpotCheck": 0,
          "ClinicianID": ""
        }
      ],
      snackbarStart: {
                message: "",
                open: false,
                color:""
            },
    }
  },
  store,
  computed: {
    devicePCorNot() {
      if (store.getters.getDevice == "Win32" || store.getters.getDevice == "Win64") {
        return true
      }
      return false
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth >= 1600) {
        this.isMobile = 4
      } else if (window.innerWidth < 1600 && window.innerWidth >= 1200) {
        this.isMobile = 3
      } else if (window.innerWidth < 1200 && window.innerWidth >= 800) {
        this.isMobile = 2
      } else {
        this.isMobile = 1
      }
      this.layout = {
        plot_bgcolor: "rgb(226, 223, 190)",
        paper_bgcolor: "rgb(226, 223, 190)",
        title: 'Monitor Record',
        showlegend: true,
        dragmode: 'pan',
        showgrid: true,
        xaxis: {
          autorange: false,
          title: 'time',
          /* range: [] */
        },
        xaxis2: {
          gridwidth: 0.2,
          anchor: 'x2',
          matches: 'x'
        },
        xaxis3: {
          gridwidth: 0.2,
          anchor: 'x3',
          matches: 'x'
        },
        xaxis4: {
          gridwidth: 0.2,
          anchor: 'x4',
          matches: 'x'
        },
        xaxis5: {
          gridwidth: 0.2,
          anchor: 'x5',
          matches: 'x'
        },

        yaxis: {
          domain: [0, 0.1],
          title: 'annotation',
          visible: false
        },
        yaxis2: {
          domain: [0.75, 1],
          anchor: 'y2',
          title: "Temperature",
          range: [32, 40]
        },
        yaxis3: {
          domain: [0.35, 0.5],
          anchor: 'y3',
          title: "other parameter",
        },
        yaxis4: {
          domain: [0.55, 0.7],
          anchor: 'y4',
          title: "SPO2/PR",
          range: [0, 100]
        },
        yaxis5: {
          domain: [0.12, 0.35],
          title: 'nibp/ibp',
          range: [0, 200],
          anchor: 'y5',
        },
        annotations: [],
        width: this.devicePCorNot ? document.documentElement.clientWidth * 1 : document.documentElement.clientWidth * 1,
        height: document.documentElement.clientHeight * 1.7,
      }
      this.getDashdata()
    },


    async changeXAxis() {
      this.layout.xaxis.range = [moment(this.selectDate + " " + this.starttime).format("YYYY-MM-DD HH:mm:ss"), moment(this.selectDate + " " + this.starttime).add(3, 'hour').format("YYYY-MM-DD HH:mm:ss")]
    },

    async getDashdata() {
      if (process.env.VUE_APP_DEMO == "true") {
        this.dataTableItems = []
        var dvt = this.demoData
        /*           this.layout.annotations=[] */
        this.dataVitalSign.forEach((e, i) => { e.x = []; e.y = []; if (i < 9) { if (i > 2) { e.marker.line.color = []; e.marker.line.width = [] } else { e.textfont.color = [] } } });
        dvt.forEach((el, index) => {

          if (index == 0) {
            this.bed = el.bed == "" ? "No bed name entered" : el.bed
            this.name = el.LastName == "" || el.FirstName == "" ? "No Full name entered" : el.FirstName + "," + el.LastName
          }

          //chart part
          el.sys == "" ? this.dataVitalSign[0].y.push(null) : this.dataVitalSign[0].y.push(el.sys)
          /* el.sys >= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-sys')].range[1] || el.sys <= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-sys')].range[0] ? this.dataVitalSign[0].textfont.color.push('red') : this.dataVitalSign[0].textfont.color.push('black') */
          el.nibppr == "" ? this.dataVitalSign[1].y.push(null) : this.dataVitalSign[1].y.push(el.nibppr)
          /* el.nibppr >= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-pulse rate')].range[1] || el.nibppr <= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-pulse rate')].range[0] ? this.dataVitalSign[1].textfont.color.push('red') : this.dataVitalSign[1].textfont.color.push('black') */
          el.dia == "" ? this.dataVitalSign[2].y.push(null) : this.dataVitalSign[2].y.push(el.dia)
          /*  el.dia >= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-dia')].range[1] || el.dia <= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-dia')].range[0] ? this.dataVitalSign[2].textfont.color.push('red') : this.dataVitalSign[2].textfont.color.push('black') */

          el.rr == "" ? this.dataVitalSign[3].y.push(null) : this.dataVitalSign[3].y.push(el.rr)
          /*             el.rr >= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[1] || el.rr <= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[0] ? this.dataVitalSign[3].marker.line.color.push('red') : this.dataVitalSign[3].marker.line.color.push('white')
                      el.rr >= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[1] || el.rr <= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[0] ? this.dataVitalSign[3].marker.line.width.push(2) : this.dataVitalSign[3].marker.line.width.push(0.5) */

          el.spo2pr == "" ? this.dataVitalSign[4].y.push(null) : this.dataVitalSign[4].y.push(el.spo2pr)
          /*             el.spo2pr >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[1] || el.spo2pr <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[0] ? this.dataVitalSign[4].marker.line.color.push('red') : this.dataVitalSign[4].marker.line.color.push('white')
                      el.spo2pr >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[1] || el.spo2pr <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[0] ? this.dataVitalSign[4].marker.line.width.push(2) : this.dataVitalSign[4].marker.line.width.push(0.5) */

          el.spo2 == "" ? this.dataVitalSign[5].y.push(null) : this.dataVitalSign[5].y.push(el.spo2)
          /*             el.spo2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[1] || el.spo2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[0] ? this.dataVitalSign[5].marker.line.color.push('red') : this.dataVitalSign[5].marker.line.color.push('white')
                      el.spo2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[1] || el.spo2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[0] ? this.dataVitalSign[5].marker.line.width.push(2) : this.dataVitalSign[5].marker.line.width.push(0.5) */

          el.temp == "" ? this.dataVitalSign[6].y.push(null) : this.dataVitalSign[6].y.push(el.temp)
          /*             el.temp >= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[1] || el.temp <= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[0] ? this.dataVitalSign[6].marker.line.color.push('red') : this.dataVitalSign[6].marker.line.color.push('white')
                      el.temp >= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[1] || el.temp <= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[0] ? this.dataVitalSign[6].marker.line.width.push(2) : this.dataVitalSign[6].marker.line.width.push(0.5) */

          el.etco2 == "" ? this.dataVitalSign[7].y.push(null) : this.dataVitalSign[7].y.push(el.etco2)
          /*             el.etco2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[1] || el.etco2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[0] ? this.dataVitalSign[7].marker.line.color.push('red') : this.dataVitalSign[7].marker.line.color.push('white')
                      el.etco2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[1] || el.etco2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[0] ? this.dataVitalSign[7].marker.line.width.push(2) : this.dataVitalSign[7].marker.line.width.push(0.5) */

          el.hr == "" ? this.dataVitalSign[8].y.push(null) : this.dataVitalSign[8].y.push(el.hr)
          /*             el.hr >= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[1] || el.hr <= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[0] ? this.dataVitalSign[8].marker.line.color.push('red') : this.dataVitalSign[8].marker.line.color.push('white')
                      el.hr >= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[1] || el.hr <= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[0] ? this.dataVitalSign[8].marker.line.width.push(2) : this.dataVitalSign[8].marker.line.width.push(0.5) */

          this.dataVitalSign[9].y.push(null)

          this.dataVitalSign.forEach((e) => { e.x.push(el.TS) });

          //trend part
          var dataTableItem = {};
          dataTableItem.TS = returnNull(el.TS)
          dataTableItem.bedID = ((el.bed == "" ? "No entry" : el.bed) + " / " + (el.PID == "" ? "No entry" : el.PID))
          dataTableItem.nibp = returnNull(el.sys) + " / " + returnNull(el.dia) + " ( " + returnNull(el.mean) + " )\xA0\xA0\x0APR : " + returnNull(el.nibppr)
          dataTableItem.spo2 = returnNull(el.spo2) + "\xA0\xA0PR : " + returnNull(el.spo2pr)
          dataTableItem.temp = returnNull(el.temp)
          dataTableItem.rr = returnNull(el.rr)
          dataTableItem.hr = returnNull(el.hr)
          dataTableItem.etco2 = returnNull(el.etco2)
          dataTableItem.scoring = returnNull(el.EWS_SYSTEM_NAME)
          dataTableItem.scoringTotal = returnNull(el.EWS_SCORE_TOTAL)
          if (el.EWS_SCORE_TOTAL != "") {
            dataTableItem.scoringColor = el.EWS_SCORE_TOTAL >= 7 ? "red" : el.EWS_SCORE_TOTAL >= 4 ? "orange" : el.EWS_SCORE_TOTAL >= 1 ? "green" : 'white'
          } else {
            dataTableItem.scoringColor = "grey"
          }
          this.dataTableItems.push(dataTableItem)

        });

        this.starttime = dvt[0].TS
        if (this.autoUpdateFuncShow == true) {
          this.layout.xaxis.range = [moment(dvt[0].TS).subtract(3, 'hour').format("YYYY-MM-DD HH:mm:ss"), moment(dvt[0].TS).add(2, 'hour').format("YYYY-MM-DD HH:mm:ss")]
        } else {
          this.layout.xaxis.range = []
          this.layout.xaxis.autorange = true
        }
        while (this.layout.annotations.length !== 0) { this.layout.annotations.pop() }
        if (this.annotationGraph != null) {
          this.annotationGraph.forEach((e) => {
            let annotationgraphitem = {
              x: e.t,
              y: -1,
              xref: 'x',
              yref: 'y',
              xanchor: "x",
              text: '    <br>   ',
              hovertext: e.annotation,
              showarrow: true,
              arrowhead: 5,
              captureevents: true,
              ax: 0,
              ay: -40
            }
            this.layout.annotations.push(annotationgraphitem)
          })
        }
      } else {
        let url1 = process.env.VUE_APP_API + "/api/queryVitalSignByMachineID";
        this.loading = true;
        /* await this.getAlarmSetting().catch((e) => { console.log(e) }) */
        await this.getAnnotation().catch((e) => { console.log(e) })
        await axios.post(url1,
          {
            MachineID: await this.decryption(this.MAC),
            interval: this.selectInterval.value,
            date: this.selectDate,
            Bed: returnNoEntryToEmpty(this.additionInformation.Bed),
            PID: returnNoEntryToEmpty(this.additionInformation.PID)
          },
          {
            /*           headers: {
                        'x-access-token': this.$cookies.get("user-info").accessToken
                      } */
          })
          .then(async (value) => {

            /* await this.getAlarmSetting() */
            this.dataTableItems = []
            console.log(value.data.data)
            var dvt = value.data.data
            /*           if (process.env.VUE_APP_DEMO == "true") {
                        dvt = this.demoData
                      } */
            /*           this.layout.annotations=[] */
            this.dataVitalSign.forEach((e, i) => { e.x = []; e.y = []; if (i < 9) { if (i > 2) { e.marker.line.color = []; e.marker.line.width = [] } else { e.textfont.color = [] } } });
            dvt.forEach((el, index) => {

              if (index == 0) {
                this.bed = el.bed == "" ? "No bed name entered" : el.bed
                this.name = el.LastName == "" || el.FirstName == "" ? "No Full name entered" : el.FirstName + "," + el.LastName
              }

              //chart part
              el.sys == "" ? this.dataVitalSign[0].y.push(null) : this.dataVitalSign[0].y.push(el.sys)
              /* el.sys >= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-sys')].range[1] || el.sys <= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-sys')].range[0] ? this.dataVitalSign[0].textfont.color.push('red') : this.dataVitalSign[0].textfont.color.push('black') */
              el.nibppr == "" ? this.dataVitalSign[1].y.push(null) : this.dataVitalSign[1].y.push(el.nibppr)
              /* el.nibppr >= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-pulse rate')].range[1] || el.nibppr <= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-pulse rate')].range[0] ? this.dataVitalSign[1].textfont.color.push('red') : this.dataVitalSign[1].textfont.color.push('black') */
              el.dia == "" ? this.dataVitalSign[2].y.push(null) : this.dataVitalSign[2].y.push(el.dia)
              /*  el.dia >= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-dia')].range[1] || el.dia <= this.alertItem[findeIndexAlarm(this.alertItem, 'NIBP-dia')].range[0] ? this.dataVitalSign[2].textfont.color.push('red') : this.dataVitalSign[2].textfont.color.push('black') */

              el.rr == "" ? this.dataVitalSign[3].y.push(null) : this.dataVitalSign[3].y.push(el.rr)
              /*             el.rr >= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[1] || el.rr <= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[0] ? this.dataVitalSign[3].marker.line.color.push('red') : this.dataVitalSign[3].marker.line.color.push('white')
                          el.rr >= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[1] || el.rr <= this.alertItem[findeIndexAlarm(this.alertItem, 'Respiration rate')].range[0] ? this.dataVitalSign[3].marker.line.width.push(2) : this.dataVitalSign[3].marker.line.width.push(0.5) */

              el.spo2pr == "" ? this.dataVitalSign[4].y.push(null) : this.dataVitalSign[4].y.push(el.spo2pr)
              /*             el.spo2pr >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[1] || el.spo2pr <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[0] ? this.dataVitalSign[4].marker.line.color.push('red') : this.dataVitalSign[4].marker.line.color.push('white')
                          el.spo2pr >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[1] || el.spo2pr <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2-pulse rate')].range[0] ? this.dataVitalSign[4].marker.line.width.push(2) : this.dataVitalSign[4].marker.line.width.push(0.5) */

              el.spo2 == "" ? this.dataVitalSign[5].y.push(null) : this.dataVitalSign[5].y.push(el.spo2)
              /*             el.spo2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[1] || el.spo2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[0] ? this.dataVitalSign[5].marker.line.color.push('red') : this.dataVitalSign[5].marker.line.color.push('white')
                          el.spo2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[1] || el.spo2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'SPO2')].range[0] ? this.dataVitalSign[5].marker.line.width.push(2) : this.dataVitalSign[5].marker.line.width.push(0.5) */

              el.temp == "" ? this.dataVitalSign[6].y.push(null) : this.dataVitalSign[6].y.push(el.temp)
              /*             el.temp >= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[1] || el.temp <= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[0] ? this.dataVitalSign[6].marker.line.color.push('red') : this.dataVitalSign[6].marker.line.color.push('white')
                          el.temp >= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[1] || el.temp <= this.alertItem[findeIndexAlarm(this.alertItem, 'temperature')].range[0] ? this.dataVitalSign[6].marker.line.width.push(2) : this.dataVitalSign[6].marker.line.width.push(0.5) */

              el.etco2 == "" ? this.dataVitalSign[7].y.push(null) : this.dataVitalSign[7].y.push(el.etco2)
              /*             el.etco2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[1] || el.etco2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[0] ? this.dataVitalSign[7].marker.line.color.push('red') : this.dataVitalSign[7].marker.line.color.push('white')
                          el.etco2 >= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[1] || el.etco2 <= this.alertItem[findeIndexAlarm(this.alertItem, 'Et-CO2')].range[0] ? this.dataVitalSign[7].marker.line.width.push(2) : this.dataVitalSign[7].marker.line.width.push(0.5) */

              el.hr == "" ? this.dataVitalSign[8].y.push(null) : this.dataVitalSign[8].y.push(el.hr)
              /*             el.hr >= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[1] || el.hr <= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[0] ? this.dataVitalSign[8].marker.line.color.push('red') : this.dataVitalSign[8].marker.line.color.push('white')
                          el.hr >= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[1] || el.hr <= this.alertItem[findeIndexAlarm(this.alertItem, 'ECG-HR')].range[0] ? this.dataVitalSign[8].marker.line.width.push(2) : this.dataVitalSign[8].marker.line.width.push(0.5) */

              this.dataVitalSign[9].y.push(null)

              this.dataVitalSign.forEach((e) => { e.x.push(el.TS) });

              //trend part
              var dataTableItem = {};
              dataTableItem.TS = returnNull(el.TS)
              dataTableItem.bedID = ((el.bed == "" ? "No entry" : el.bed) + " / " + (el.PID == "" ? "No entry" : el.PID))
              dataTableItem.nibp = returnNull(el.sys) + " / " + returnNull(el.dia) + " ( " + returnNull(el.mean) + " )\xA0\xA0\x0APR : " + returnNull(el.nibppr)
              dataTableItem.spo2 = returnNull(el.spo2) + "\xA0\xA0PR : " + returnNull(el.spo2pr)
              dataTableItem.temp = returnNull(el.temp)
              dataTableItem.rr = returnNull(el.rr)
              dataTableItem.hr = returnNull(el.hr)
              dataTableItem.etco2 = returnNull(el.etco2)
              dataTableItem.scoring = returnNull(el.EWS_SYSTEM_NAME)
              dataTableItem.scoringTotal = returnNull(el.EWS_SCORE_TOTAL)
              if (el.EWS_SCORE_TOTAL != "") {
                dataTableItem.scoringColor = el.EWS_SCORE_TOTAL >= 7 ? "red" : el.EWS_SCORE_TOTAL >= 4 ? "orange" : el.EWS_SCORE_TOTAL >= 1 ? "green" : 'white'
              } else {
                dataTableItem.scoringColor = "grey"
              }
              this.dataTableItems.push(dataTableItem)

            });

            this.starttime = dvt[0].TS
            if (this.autoUpdateFuncShow == true) {
              this.layout.xaxis.range = [moment(dvt[0].TS).subtract(3, 'hour').format("YYYY-MM-DD HH:mm:ss"), moment(dvt[0].TS).add(2, 'hour').format("YYYY-MM-DD HH:mm:ss")]
            } else {
              this.layout.xaxis.range = []
              this.layout.xaxis.autorange = true
            }
            while (this.layout.annotations.length !== 0) { this.layout.annotations.pop() }
            if (this.annotationGraph != null) {
              this.annotationGraph.forEach((e) => {
                let annotationgraphitem = {
                  x: e.t,
                  y: -1,
                  xref: 'x',
                  yref: 'y',
                  xanchor: "x",
                  text: '    <br>   ',
                  hovertext: e.annotation,
                  showarrow: true,
                  arrowhead: 5,
                  captureevents: true,
                  ax: 0,
                  ay: -40
                }
                this.layout.annotations.push(annotationgraphitem)
              })
            }
          })
          .catch((err) => { console.log(err) })


      }
      function returnNull(value) {
        return value == "" ? "---" : value
      }
      /*       function findeIndexAlarm(alertItem, parameter) {
              return alertItem.map(function (o) { return o.name; }).indexOf(parameter);
            } */

      this.loading = false;

    },
    showQRcodeFunc(value) {
      var bed = value.bedID.substring(0, value.bedID.indexOf(' /'))
      var PID = value.bedID.substring(value.bedID.indexOf('/') + 2, value.bedID.length)
      this.showQRvalue = {
        PID: PID == "No entry" ? "" : PID,
        bed: bed,
        TS: value.TS,
        sys: value.nibp.substring(0, value.nibp.indexOf('/')),
        dia: value.nibp.substring(value.nibp.indexOf('/') + 1, value.nibp.indexOf('(')),
        mean: value.nibp.substring(value.nibp.indexOf('(') + 1, value.nibp.indexOf(')')),
        nibppr: value.nibp.substring(value.nibp.indexOf('PR : ') + 5, value.nibp.length),
        spo2pr: value.spo2.substring(value.spo2.indexOf('PR : ') + 5, value.spo2.length),
        spo2: value.spo2.substring(0, value.spo2.indexOf('PR :')),
        previousNIBP: {},
        temp: value.temp,
        rr: value.rr,
        hr: value.hr
      }
      this.showQRcode = true
    },
    async decryption(value) {
      var bytes = CryptoJS.AES.decrypt(decodeURIComponent(value), process.env.VUE_APP_FRONT_CODE);
      var original = bytes.toString(CryptoJS.enc.Utf8);
      return original;
    },

    openAlertDialog() {
      this.getAlarmSetting();
      this.alertDrawer = !this.alertDrawer
    },
    async updateAlert(/* value */) {
      //await this.updatePatientAlert(value, "update")
      await this.getDashdata()
    },
    closeAlert(value) {
      this.getAlarmSetting();
      this.alertDrawer = value
    },
    async getAlarmSetting() {
      await axios.post(process.env.VUE_APP_API + "/api/getSinglePatientAlarmSetting",
        {
          HN: this.HNcase,
        },
        {
          /*           headers: {
                      'x-access-token': this.$cookies.get("user-info").accessToken
                    } */
        }).then((data) => {
          this.alertItem = data.data.data;
        })
        .catch((e) => { console.log(e) })
    },
    async updatePatientAlert(value, mode) {
      await axios.post(process.env.VUE_APP_API + "/api/addAlertSettingToHN",
        {
          HN: this.HNcase,
          alertSetting: value,
          alarmSetMode: mode
        },
        /* {
          headers: {
            'x-access-token': this.$cookies.get("user-info").accessToken
          }
        } */)
        .catch((response) => { console.log(response) })
    },

    async addAnnoation(value) {
      this.annotationDrawer = true
      if (Object.hasOwnProperty.call(value, 'points')) {
        this.annotationTime = value.points[0].x.replace(" ", "T")
      }
      if (Object.hasOwnProperty.call(value, 'annotation')) {
        this.annotationTime = value.annotation.x.replace(" ", "T")
      }
      if (Object.hasOwnProperty.call(value, 'tblt')) {
        this.annotationTime = value.tblt.replace(" ", "T")
      }
      await this.getAnyAnnotation(this.annotationTime.replace("T", " "))
    },
    async updateAnnotation(value) {
      await axios.post(process.env.VUE_APP_API + "/api/addAnnotationToHN",
        {
          HN: this.HNcase,
          annotation: {
            t: value.time.replace("T", " "),
            annotation: value.annotationContent
          }
        },
        /* {
          headers: {
            'x-access-token': this.$cookies.get("user-info").accessToken
          }
        } */)
        .catch((response) => { console.log(response) })
      await this.getDashdata()
    },
    async getAnnotation() {
      await axios.post(process.env.VUE_APP_API + "/api/getSinglePatientAnnotation",
        {
          HN: this.HNcase,
        },
        /* {
          headers: {
            'x-access-token': this.$cookies.get("user-info").accessToken
          }
        } */).then((data) => {
          this.annotationGraph = data.data.data;
        })
        .catch((e) => { console.log(e) })
    },
    closeAnnotation(value) {
      this.annotationDrawer = value
    },
    async getAnyAnnotation(time) {
      await axios.post(process.env.VUE_APP_API + "/api/getSinglePatientAnnotation",
        {
          HN: this.HNcase,
        },
        /* {
          headers: {
            'x-access-token': this.$cookies.get("user-info").accessToken
          }
        } */).then((data) => {
          if (data.data.data.length > 0) {
            let getAnnot = data.data.data.filter((v) => {
              return v.t === time
            })
            if (getAnnot.length > 0) {
              this.initAnnotationContent = getAnnot[0].annotation;
            } else {
              this.initAnnotationContent = ""
            }
          }
        })
        .catch((e) => { console.log(e) })
    },
    async getTimeIfInvalid() {
      await axios.post(process.env.VUE_APP_API + "/api/latestDataOfSinglePatient",
        {
          HN: this.HNcase,
        },
        /* {
          headers: {
            'x-access-token': this.$cookies.get("user-info").accessToken
          }
        } */).then((data) => {
          if (data.data.data.length > 0) {
            this.latestResult = data.data.data[0].TS
          }
        })
        .catch((e) => { console.log(e) })
    },
    seeIncludeOrNot(t) {
      if (this.annotationGraph != null) {
        return this.annotationGraph.findIndex((v) => { return v.t == t }) >= 0 ? 'light-green lighten-1' : 'blue-grey lighten-2';
      } else {
        return 'blue-grey lighten-2'
      }
    },
    closeQR(value) {
      this.showQRvalue = {
        PID:  "" ,
        bed: "",
        TS: "",
        sys: "",
        dia: "",
        mean: "",
        nibppr: "",
        spo2pr: "",
        spo2: "",
        previousNIBP: {},
        temp: "",
        rr: "",
        hr: ""
      }
      this.showQRcode = value
    },

    exportVSdata(){
      this.exporting=true
      setTimeout(()=>{
        this.snackbarStart.open = true
        this.snackbarStart.color = "green"
        this.snackbarStart.message = "Vital sign data sent sucsessfully"
        this.exporting=false
      },1000)
      setTimeout(()=>{
        this.snackbarStart.open = false
        this.snackbarStart.color = ""
        this.snackbarStart.message = ""
      },3000)
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true })
    this.timer = setInterval(async () => {
      if (this.autoUpdate == true) {
        await this.getDashdata()
      }
    }, 10000)

  },

  async created() {
    let getDat = await this.decryption(this.DATE)
    this.HNcase = await this.decryption(this.MAC) //device centric : device ID; patient centric : PID
    this.additionInformation = {
      Bed: await this.decryption(this.BED),
      PID: await this.decryption(this.HN)
    }


    if (getDat == "Invalid date") {
      await this.getTimeIfInvalid()
        .then(() => { getDat = moment(this.latestResult).format("YYYY-MM-DD") })
        .catch(e => console.log(e))
    }

    this.selectDate = getDat != "now" ? getDat : moment().format("YYYY-MM-DD")

    if (getDat == "now") {
      this.autoUpdate = true
      this.autoUpdateFuncShow = true
    }


    /*     await this.updatePatientAlert([
          {
            name: "NIBP-sys",
            range: [90, 160]
          },
          {
            name: "NIBP-dia",
            range: [50, 90]
          },
          {
            name: "NIBP-pulse rate",
            range: [50, 120]
          },
          {
            name: "SPO2",
            range: [90, 100]
          },
          {
            name: "SPO2-pulse rate",
            range: [50, 120]
          },
          {
            name: "Respiration rate",
            range: [8, 30]
          },
          {
            name: "temperature",
            range: [35.0, 38.0]
          },
          {
            name: "Et-CO2",
            range: [25, 50]
          },
          {
            name: "ECG-HR",
            range: [50, 120]
          }
        ]).then(async () => {  }) */
    await this.getDashdata();

  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}



</script>

<style>
g.pointtext {
  display: none;
}
</style>

