import { PDFDocument ,rgb } from 'pdf-lib'
import Blob from 'blob';
import fontkit from '@pdf-lib/fontkit'
import { formField } from './formField.js'
const cufont = require('./ArialUnicode.ttf')
const tic = 'M 0 0 L 4 4 M 3 5 L 9 -4';



async function readPDF(path) {
    const formPdfBytes = await fetch('/rawpdf/' + path + ".pdf").then(res => res.arrayBuffer()).catch(e => console.log(e))
    const pdfDoc = await PDFDocument.load(formPdfBytes)
    pdfDoc.registerFontkit(fontkit)
    const url = cufont
    const fontBytes = await fetch(url).then((res) => res.arrayBuffer())
    const cusFont = await pdfDoc.embedFont(fontBytes)
    return {
        pdfDoc: pdfDoc,
        cusFont: cusFont
    };
}

//need refine location data in another file for input?
function formType(formType) {
    //include geometric location of different formType {x,y,name,type}
    let input = []
    console.log(formField)
    switch (formType) {
        case "HKVH-MUST":
            input = formField.filter(e => e.name == formType)[0].input
            break;
        default:
            input = ["invalid"]
    }
    return input
}

function drawText(x, y, value, pageNo, font) {
    pageNo.drawText(value,
        { x: x, y: y, size: 10, font: font }
    )
}
function drawCheckBox(x, y, pageNo,) {
    pageNo.drawSvgPath(tic, { 
        x: x, y: y, 
        opacity: 1, 
        borderColor: rgb(0 / 255, 0 / 255, 0 / 255),
        borderWidth: 3, borderOpacity: 1, })
}

var blob;
var pdfBytes;
//need pass back blob then try use pdfview setDocument function
export const formInput = (type, input) => {
    //match name and draw text according to that x or y
    var formIn = formType(type)
    console.log(input)
    readPDF(type)
        .then(async (data) => {
            const pages = data.pdfDoc.getPages()
            pages.forEach((e, i) => {
                formIn.forEach((v) => {
                    if (v.page == i + 1) {
                        input.forEach((val) => {
                            if (v.fields.filter(j => j.name == val.name).length > 0) {
                                var coord = v.fields.filter(j => j.name == val.name)[0]
                                if (val.type == "text") {
                                    drawText(coord.x, coord.y, val.value, e, data.cusFont)
                                }
                                if (val.type == "checkbox") {
                                    if(val.value == true){
                                        drawCheckBox(coord.x, coord.y, e, )
                                        console.log(val)
                                    }
                                }
                            }
                        })
                    }
                })
            })


            pdfBytes = await data.pdfDoc.save()
            blob = new Blob([pdfBytes], { type: 'application/pdf' });


        })
        .catch(e => console.log(e))
}

export const getblob = () => {
    return blob
}

export const getbyte = () => {
    return pdfBytes
}




