<template>
    <v-card class="elevation-0" :color="al" height="120px">
        <v-container fluid class="" fill-width fill-height>
            <v-row>
                <span :class="al == 'dark' ? styleChoose : 'alarmingParaText'" class="text-h6 font-weight-bold">{{ title }}</span>
                <span :class="al == 'dark' ? styleChoose : 'alarmingParaText'" class="text-caption ml-1 mt-3">{{ unit }}</span>
            </v-row>
            <v-row justify="center">
                <v-sheet v-if="title == 'NIBP'" cols="12" class="pa-0 mt-2" :color="al">
                    <v-col :class="al == 'dark' ? styleChoose : 'alarmingParaText'" class="pa-0 text-h4 font-weight-bold">
                        {{ data }}
                        <div v-if="subPara != '' && title == 'NIBP'" class="pa-0 text-h5 font-weight-bold">
                            ({{ subPara }})
                        </div>
                    </v-col>
                </v-sheet>
                <v-sheet v-else class="pa-0 mt-2" :color="al">
                    <v-col cols="12" :class="al == 'dark' ? styleChoose : 'alarmingParaText'"
                        class="pa-0  text-h2 font-weight-bold">
                        {{ data }}
                    </v-col>
                </v-sheet>
            </v-row>
            <!--             <v-row v-if="subPara!=''&&title=='NIBP'" no-gutters>
                <v-col cols="12" :class="styleChoose" class="text-body-1 font-weight-bold">
                     ({{ subPara }})
                </v-col>
            </v-row> -->
        </v-container>
    </v-card>
</template>


<script>

export default {
    data() {
        return {
            data: this.vitalSignPara,
            al: this.alarming,
            flashing: "dark",
            timer: null
        }
    },
    props: {
        title: {
            type: String
        },
        unit: {
            type: String
        },
        vitalSignPara: {
            type: String,
        },
        styleChoose: {
            type: String
        },
        subPara: {
            type: String,
            default: ''
        },
        alarming: {
            type: String,
        }
    },
    watch: {
        vitalSignPara: {
            handler: function (n) {
                if (n != undefined && n != null) {
                    this.data = n
                }
            },
            deep: true
        },
        alarming: {
            handler: function (n) {
                if (n != undefined && n != null) {
                    this.al = n
                }
            },
            deep: true
        },
    },
    async mounted() {
        this.timer = setInterval(() => {
            if (this.alarming != 'dark') {
                if (this.al == 'dark') {
                    this.al = this.alarming
                } else {
                    this.al = 'dark'
                }
            }else{
                this.al='dark'
            }
        }, 500)


    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    destroyed() {
        clearInterval(this.timer)
    }

}

</script>