export const genStr = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export const onResize = (innerWidth) =>{
  if (innerWidth >= 1600) {
      return 4
  } else if (innerWidth < 1600 && innerWidth >= 1200) {
      return 3
  } else if (innerWidth < 1200 && innerWidth >= 800) {
      return 2
  } else {
      return 1
  }
}

export const returnNoEntryToEmpty = (value) =>{
    if(value=="No entry"){
        return "" 
    }else{
        return value;
    }
}