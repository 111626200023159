<template>
    <qrcode-vue :value="value" :size="size" :background="background" :foreground="foreground" level="L" />
  </template>
  <script>
    import QrcodeVue from 'qrcode.vue'
  
    export default {
      props: {
          value: String,
          background: String,
          foreground: String,
          size: Number,
      },
      components: {
        QrcodeVue,
      },
    }
  </script>