/* import Vue from 'vue'
import Vuex from 'vuex'

import device from '@/vuex/modules/device'
import drawerState from '@/vuex/modules/drawerState'
import setting from '@/vuex/modules/settingStore'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
    modules: {
        device,
        drawerState,
        setting,               
    },
    strict: debug,
    middlewares: []
}) */

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    }
  },
  mutations:{
    SOCKET_ONOPEN (state, event)  {
      console.log(state)
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, )  {
      /* console.log(state) */
      state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event)  {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message)  {
      state.socket.message = message
    },
    // mutations for reconnect methods
    /* SOCKET_RECONNECT(state, count) {
      console.info(state, count)
    }, */
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
  },
  actions: {
    sendMessage: function(context, message) {
      Vue.prototype.$socket.send(message)
    }
  }
})