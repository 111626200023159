<template>
    <v-card class="text-center" rounded elevation="14">
        <v-card-title class="text-h5">
            Alert Level
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-card flat color="transparent" v-for="data, index in alertItems" :key="index">
                <v-card-title class="text-h6">
                    <span v-if="index == 0" class="grey--text">low</span>
                    <v-spacer></v-spacer>
                    {{ data.name }}
                    <v-spacer></v-spacer>
                    <span v-if="index == 0" class="grey--text">High</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="px-4">
                            <v-range-slider v-model="data.range" :step="data.name == 'temperature' ? 0.1 : 1" max="200" min="0"
                                hide-details class="align-center">
                                <template v-slot:prepend>
                                    <v-text-field :value="data.range[0]" class="mt-0 pt-0" hide-details single-line
                                        :type="data.name == 'temperature' ? 'decimal' : 'number'" style="width: 60px"
                                        @change="$set(data.range, 0, $event)"></v-text-field>
                                </template>
                                <template v-slot:append>
                                    <v-text-field :value="data.range[1]" class="mt-0 pt-0" hide-details single-line
                                        :type="data.name == 'temperature' ? 'decimal' : 'number'" style="width:60px"
                                        @change="$set(data.range, 1, $event)"></v-text-field>
                                </template>
                            </v-range-slider>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="blue" @click.stop="setDefault">
                return default
            </v-btn>
            <v-btn text color="success" @click.stop="updateAlertLevel">
                update
            </v-btn>
            <v-btn text color="error" @click.stop="closeAlert">
                <SvgIcon :path="mdiClose" type="mdi" />
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
  
<script>
import Vue from 'vue';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import SvgIcon from '@jamescoyle/vue-icon'
import { mdiClose } from '@mdi/js';


export default {
    components: {
        SvgIcon
    },
    props: {
        sheet: Boolean,
        alertItems: Array
    },
    data() {
        return {
            mdiClose: mdiClose,
        }
    },
    methods: {
        setDefault(){
            this.$emit('updateAlertItem',[
            {
              name: "NIBP-sys",
              range: [90, 160]
            },
            {
              name: "NIBP-dia",
              range: [50, 90]
            },
            {
              name: "NIBP-pulse rate",
              range: [50, 120]
            },
            {
              name: "SPO2",
              range: [90, 100]
            },
            {
              name: "SPO2-pulse rate",
              range: [50, 120]
            },
            {
              name: "Respiration rate",
              range: [8, 30]
            },
            {
              name: "temperature",
              range: [35.0, 38.0]
            },
            {
              name: "Et-CO2",
              range: [25, 50]
            },
            {
              name: "ECG-HR",
              range: [50, 120]
            }
          ])
          this.$emit('close', false)    
        },
        updateAlertLevel() {
            this.$emit('updateAlertItem',this.alertItems)
            this.$emit('close', false)
        },
        closeAlert() {
            this.$emit('close', false)
        }
    }
};


</script>