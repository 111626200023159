<template>
    <div>
        <v-app-bar color="#2D5AD0" dark dense :collapse="hoverOnAppBar||login" @mouseenter="hoverOnAppBar=false" @mouseleave="hoverOnAppBar=true" absolute>

            <v-btn v-if="!login" @click.stop="openDrawer()" v-click-outside="onClickOutside" icon>
                <SvgIcon type="mdi" :path="mdiMenu"></SvgIcon>
            </v-btn>

            <v-app-bar-title class="title grow">Vital Sign Location Dashboard</v-app-bar-title>

            <v-spacer></v-spacer>

            <v-btn v-if="login" icon class="mx-5" router link :to="'/Login'">
                <SvgIcon type="mdi" :path="loginIcon"></SvgIcon>
                Log in
            </v-btn>
        </v-app-bar>


        <navbar v-if="!login" :show="show" />
    </div>
</template>

<script>
import navbar from './navDrawer.vue'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiLogin, mdiMenu } from '@mdi/js';
/* import deviceDashboard from '../views/monitoring/deviceDashboard.vue' */

export default {
    components: {
        SvgIcon,
        navbar,
        /* deviceDashboard */
    },
    props: {
        login: Boolean
    },
    data() {
        return {
            loginIcon: mdiLogin,
            mdiMenu: mdiMenu,
            show: false,
            tabs: ["Vital Sign", "Location", "Pairing"],
            TabComponents: "Vital Sign",
            hoverOnAppBar:true

        }
    },
    methods: {
        openDrawer() {
            this.show = !this.show
        },
        onClickOutside() {
            if (this.show == true) {
                this.show = false
            }
        }
    }
}
</script>