import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSmartWidget from "vue-smart-widget";
import SvgIcon from '@jamescoyle/vue-icon'
import store from './vuex/store.js'

import VueNativeSock from 'vue-native-websocket'

Vue.use(VueNativeSock,  process.env.VUE_APP_WS,
  {
    connectManually: true,
    store: store,
    format: 'json',
    reconnection: true,
    reconnectionDelay: 1000,
  })
Vue.use(VueSmartWidget);

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

import VueZoomer from 'vue-zoomer'

Vue.use(VueZoomer)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  SvgIcon,
  VueSmartWidget,
  render: h => h(App)
}).$mount('#app')
