<template>
    <v-card min-width="600px">
        <v-card-title class="light-blue lighten-4">
            QRcode
            <v-spacer></v-spacer>
            <v-btn @click="close()" icon color="primary">
                <SvgIcon type="mdi" :path="mdiClose"></SvgIcon>
            </v-btn>
        </v-card-title>
        <v-container>
            <v-row align-self="center">
                <v-col v-if="QRboxValue.HNQRcode !== ''">
                    <h4>Admission qrcode : {{ QRboxValue.HNQRcode }}</h4>
                    <qrcode :value="QRboxValue.HNQRcode" :size="200" />
                </v-col>
                <!-- <v-divider v-if="QRboxValue.HNQRcode !== ''" class="ms-3" inset vertical color="black"></v-divider> -->
                <v-col :cols="QRboxValue.HNQRcode == '' ? 12 : 8">
                    <v-card dark min-width="500px">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="6">
                                    <v-container fluid>
                                        <v-row no-gutters>
                                            <v-col class="text-h5">
                                                <v-sheet class="pa-1 mx-1 mb-1 rounded-lg text-center" color="grey">
                                                    Bed: {{ QRboxValue.vitalSignValue.MID }}
                                                </v-sheet>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mt-1 nibpText text-caption">
                                                <span class="font-weight-bold">
                                                    NIBP: <span class="text-h5"
                                                        :class="{ 'shallowText': Object.keys(vitalSign.previousNIBP).length > 0 && vitalSign.sys == '' }">{{
                                                            QRboxValue.vitalSignValue.n_s }}/{{ QRboxValue.vitalSignValue.n_d }}
                                                        ({{ QRboxValue.vitalSignValue.n_mean }})
                                                    </span>
                                                </span>
                                                <span class="text-h5 font-weight-bold text-caption">
                                                    PR: <span class="text-h5"
                                                        :class="{ 'shallowText': Object.keys(vitalSign.previousNIBP).length > 0 && vitalSign.sys == '' }">{{
                                                            QRboxValue.vitalSignValue.n_pr }}
                                                    </span>
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mt-1 spo2Text text-caption">SPO2: <span class="text-h5">{{
                                                QRboxValue.vitalSignValue.sp }}</span>
                                                PR: <span class="text-h5">
                                                    {{ QRboxValue.vitalSignValue.pr }}</span></v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mt-1 tempText text-caption">TEMP: <span class="text-h5">{{
                                                QRboxValue.vitalSignValue.temp }}</span></v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mt-1 rrText text-caption">Resp: <span class="text-h5">{{
                                                QRboxValue.vitalSignValue.rr }}</span></v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mt-1 ecghrText text-caption">ECGHR: <span class="text-h5">{{
                                                QRboxValue.vitalSignValue.hr }}</span></v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="grey--text text-h8">update: {{
                                                QRboxValue.vitalSignValue.t }}</v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="5">
                                    <h4>Vital QR code</h4>
                                    <div class="pa-1" style="background-color: white; aspect-ratio: 1; width:220px">
                                        <qrcode class="ma-2 pa-2" background-color="white" dark
                                            :value="QRboxValue.QRcodeValue" :size="180" :foreground="'#000000'"
                                            :background="'#ffffff'" />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import qrcode from "../components/qrcodeComponent.vue"
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiClose } from '@mdi/js';

export default {
    data() {
        return {
            QRboxValue: {
                QRcodeValue: "",
                HNQRcode: "",
                vitalSignValue: {}
            },
            mdiClose: mdiClose
        }
    },
    props: {
        vitalSign: Object,
    },
    components: {
        qrcode,
        SvgIcon
    },
    computed: {
        VSvalue() {
            return this.vitalSign;
        }
    },
    watch: {
        vitalSign: {
            handler() {
                this.showQRcodeFunc(this.vitalSign)
            },
            immediate: true
        },
    },
    methods: {
        showQRcodeFunc(value) {
            if (value == "") {
                this.QRboxValue.QRcodeValue = ""
                this.QRboxValue.HNQRcode = ""
                this.QRboxValue.vitalSignValue = ""
                this.showQRcode = false
            } else {
                this.QRboxValue.HNQRcode = value.PID
                this.QRboxValue.QRcodeValue = JSON.stringify({
                    MID: value.bed,
                    t: value.TS,
                    n_s: this.returnNull(Object.keys(value.previousNIBP).length > 0 && value.sys == "" ? value.previousNIBP.sys : value.sys),
                    n_d: this.returnNull(Object.keys(value.previousNIBP).length > 0 && value.dia == "" ? value.previousNIBP.dia : value.dia),
                    n_pr: this.returnNull(Object.keys(value.previousNIBP).length > 0 && value.nibppr == "" ? value.previousNIBP.nibppr : value.nibppr),
                    pr: this.returnNull(value.spo2pr),
                    sp: this.returnNull(value.spo2),
                    temp: this.returnNullF(value.temp),
                    rr: this.returnNull(value.rr),
                    hr: this.returnNull(value.hr)
                }).replace(/"<<|>>"/g, '');
                this.QRboxValue.vitalSignValue = {
                    MID: value.bed,
                    t: value.TS,
                    n_s: this.returnEmpty(Object.keys(value.previousNIBP).length > 0 && value.sys == "" ? value.previousNIBP.sys : value.sys),
                    n_d: this.returnEmpty(Object.keys(value.previousNIBP).length > 0 && value.dia == "" ? value.previousNIBP.dia : value.dia),
                    n_mean: this.returnEmpty(Object.keys(value.previousNIBP).length > 0 && value.mean == "" ? value.previousNIBP.mean : value.mean),
                    n_pr: this.returnEmpty(Object.keys(value.previousNIBP).length > 0 && value.nibppr == "" ? value.previousNIBP.nibppr : value.nibppr),
                    pr: this.returnEmpty(value.spo2pr),
                    sp: this.returnEmpty(value.spo2),
                    temp: this.returnEmpty(value.temp),
                    rr: this.returnEmpty(value.rr),
                    hr: this.returnEmpty(value.hr)
                }
            }
        },
        returnNull(value) {
            return value == "" || value == "---" ? null : parseInt(value)
        },
        returnNullF(value) {
            return value == "" || value == "---" ? null : "<<" + parseFloat(value).toFixed(1) + ">>"
        },
        returnEmpty(value) {
            return value == "" ? "---" : value
        },
        close() {
            this.$emit('closeQR', false)
        }
    }
}




</script>