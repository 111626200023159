export const formField = [
    {
        name: "HKVH-MUST",
        input: [
            {
                page: 1,
                fields: [
                    {
                        name: "HN",
                        x: 370,
                        y: 800,
                    },
                    {
                        name: "PID",
                        x: 480,
                        y: 800,
                    },
                    {
                        name: "name",
                        x: 350,
                        y: 775,
                    },
                    {
                        name: "Sex",
                        x: 325,
                        y: 750,
                    },
                    {
                        name: "Age",
                        x: 380,
                        y: 750,
                    },
                    //need add font kit for Chinese
                    {
                        name: "passedDataChinese",
                        x: 480,
                        y: 750,
                    },
                    //need add checkbox
                    {
                        name: "ward",
                        x: 332,
                        y: 725,
                    },
                    {
                        name: "bed",
                        x: 405,
                        y: 725,
                    },
                    {
                        name: "Dept",
                        x: 500,
                        y: 725,
                    },

                    {
                        name: "assessedNR",
                        x: 220,
                        y: 690,
                    },
                    {
                        name: "assessedDate",
                        x: 440,
                        y: 690,
                    },
                    {
                        name: "assessedNAReason",
                        x: 300,
                        y: 660,
                    },

                    {
                        name: "BW",
                        x: 110,
                        y: 602,
                    },
                    {
                        name: "BH",
                        x: 101,
                        y: 587,
                    },
                    {
                        name: "BMI.cal",
                        x: 170,
                        y: 592,
                    },

                    {
                        name: "weightLoss.BW",
                        x: 165,
                        y: 550,
                    },
                    {
                        name: "weightLoss.currentBW",
                        x: 268,
                        y: 550,
                    },
                    {
                        name: "weightLoss.pastBW",
                        x: 211,
                        y: 530,
                    },
                    {
                        name: "weightLoss.cal",
                        x: 355,
                        y: 539,
                    },

                    {
                        name: "totalScore",
                        x: 500,
                        y: 446,
                    },

                    {
                        name: "BMI.choiceI",
                        x: 527,
                        y: 627,
                    },
                    {
                        name: "BMI.choiceII",
                        x: 527,
                        y: 610,
                    },
                    {
                        name: "BMI.choiceIII",
                        x: 527,
                        y: 592,
                    },

                    {
                        name: "weightLoss.choiceI",
                        x: 527,
                        y: 572,
                    },
                    {
                        name: "weightLoss.choiceII",
                        x: 527,
                        y: 555,
                    },
                    {
                        name: "weightLoss.choiceIII",
                        x: 527,
                        y: 536,
                    },

                    {
                        name: "acuteDisease.choiceI",
                        x: 527,
                        y: 506,
                    },
                    {
                        name: "acuteDisease.choiceII",
                        x: 527,
                        y: 487,
                    },

                    {
                        name: "Risk.choiceI",
                        x: 38,
                        y: 394,
                    },
                    {
                        name: "Risk.choiceII",
                        x: 197,
                        y: 394,
                    },
                    {
                        name: "Risk.choiceIII",
                        x: 385,
                        y: 394,
                    },



                ]
            }
        ]
    }
]