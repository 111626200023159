<template>
    <v-card dark  height="170px" :hover="data.hover" @mouseenter="hov = true"
        @mouseleave="hov = false"><!-- :class="{ nonActive: !data.isActive }" -->
        <v-container fluid fill-height fill-width class="ma-0 pa-0" dense>
            <v-row no-gutters align="center" align-self="center">
                <v-col :cols="openQR ? '9' : ''">
                    <v-row no-gutters>
                        <v-col v-if="device.includes('ecg')" cols="3">
                            <vitalSignSinglePara :title="'ECG'" :alarming="alarmFlash.ecg" :styleChoose="'ecghrText'"
                                :unit="'bpm'" :vitalSignPara="data.hr" />
                        </v-col>
                        <v-col  cols="3" v-else>
                            <vitalSignSinglePara :title="'PR'" :alarming="alarmFlash.pr" :styleChoose="'spo2Text'"
                                :unit="'bpm'" :vitalSignPara="data.spo2pr" />
                        </v-col>
                        <v-col  cols="3">
                            <vitalSignSinglePara :title="'SpO2'" :alarming="alarmFlash.spo2" :styleChoose="'spo2Text'"
                                :unit="'%'" :vitalSignPara="data.spo2" />
                        </v-col>
                        <v-col  cols="2">
                            <vitalSignSinglePara :title="'RR'" :alarming="alarmFlash.rr" :styleChoose="'rrText'"
                                :unit="'rpm'" :vitalSignPara="data.rr" />
                        </v-col>
                        <v-col v-if="device.includes('nibp')"  >
                            <vitalSignSinglePara :title="'NIBP'" :alarming="alarmFlash.nibp" :styleChoose="'nibpText'"
                                :unit="'mmHg'" :vitalSignPara="data.previousNIBP.sys + '/' + data.previousNIBP.dia"
                                :subPara="data.previousNIBP.mean" />
                        </v-col>
                        <v-col v-else></v-col>
                    </v-row>
                    <v-row no-gutters v-if="device.includes('ecg')">
                        <v-col>
                            <waveform :interval="2"></waveform>
                        </v-col>
                    </v-row>
                </v-col>
                <!--                 <v-col cols="4" v-if="device.includes('nibp')">
                    <v-row no-gutters>
                        <vitalSignSinglePara :title="'NIBP'" :styleChoose="'nibpText'" :unit="'mmHg'"
                            :vitalSignPara="data.previousNIBP.sys+'/'+data.previousNIBP.dia" :subPara="data.previousNIBP.mean" />
                    </v-row>
                </v-col> -->
                <v-spacer v-if="openQR"></v-spacer>
                <v-col v-if="openQR" cols="2" class="mr-6">
                    <v-card color="white" min-width="105px" max-width="105px" max-height="105px">
                        <qrcode class="pa-1" background-color="white" dark :value="QRcodeValue" :size="95"
                            :foreground="'#000000'" :background="'#ffffff'" />
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mdiQrcode, } from '@mdi/js';
import CryptoJS from "crypto-js"
import vitalSignSinglePara from "@/components/vitalSignComponent/singleVitalSignComponent.vue"
import waveform from "@/components/waveformComponent/waveformGraph.vue"
import qrcode from "@/components/qrcodeComponent.vue"

export default {
    name: 'vitalSignCard',
    components: {
        vitalSignSinglePara,
        waveform,
        qrcode
    },
    data() {
        return {
            data: this.vitalSign,
            alarmData: this.alarm,
            qr: this.openQR,
            showAL: {},
            showIndex: 0,
            mdiQrcode: mdiQrcode,
            hov: false,
            timer: null,
            i: 0,
            interact: false,
            zone: this.Zone,
            alarmFlash: {
                ecg: 'dark',
                spo2: 'dark',
                nibp: 'dark',
                rr: 'dark',
                pr: 'dark',
            }
        }
    },
    computed: {
        QRcodeValue() {
            return JSON.stringify({
                MID: this.data.bed,
                t: this.data.TS,
                n_s: this.returnNull(Object.keys(this.data.previousNIBP).length > 0 && this.data.sys == "" ? this.data.previousNIBP.sys : this.data.sys),
                n_d: this.returnNull(Object.keys(this.data.previousNIBP).length > 0 && this.data.dia == "" ? this.data.previousNIBP.dia : this.data.dia),
                n_pr: this.returnNull(Object.keys(this.data.previousNIBP).length > 0 && this.data.nibppr == "" ? this.data.previousNIBP.nibppr : this.data.nibppr),
                pr: this.returnNull(this.data.spo2pr),
                sp: this.returnNull(this.data.spo2),
                temp: this.returnNullF(this.data.temp),
                rr: this.returnNull(this.data.rr),
                hr: this.returnNull(this.data.hr)
            }).replace(/"<<|>>"/g, '');
        },
        /* alarmFlash() {
            let ecg
            
            return {
                ecg: ecg,
                nibp: 'dark',
                rr: 'dark',
                spo2: spo2,
                pr: 'dark'
            }
        } */
    },
    props: {
        vitalSign: {
            type: Object,

        },
        alarm: {
            type: Array,
        },
        mode: {
            type: Boolean,
            default: true
        },
        Zone: {
            type: Object,

        },
        view: {
            type: String,
        },
        device: {
            type: Array,
        },
        openQR: {
            type: Boolean,
        }
    },
    watch: {
        vitalSign: {
            handler: function (n) {
                if (n != undefined && n != null) {
                    this.data = n
                }
            },
            deep: true
        },
        alarm: {
            handler: function (n) {
                n.forEach((e) => {
                    if (e.related == 'ECG') {
                        this.alarmFlash.ecg = e.priorty
                    }
                    if (e.related == 'SPO2') {
                        this.alarmFlash.spo2 = e.priorty
                    }
                })
            },
            deep: true
        },
        openQR: function (n) {
            if (n != undefined && n != null) {
                this.qr = n
            }
        },
        mode: function (n) {
            if (n != undefined && n != null) {
                this.interact = n
            }
        },
        Zone: function (n) {
            if (n != undefined && n != null) {
                this.zone = n
            }
        },
    },
    methods: {
        parseIfNotNullUndefined(value) {
            return value == null || value == undefined ? false : true
        },
        returnNull(value) {
            return value == "" ? null : parseInt(value)
        },
        returnNullF(value) {
            return value == "" ? null : "<<" + parseFloat(value).toFixed(1) + ">>"
        },
        returnEmpty(value) {
            return value == "" ? "---" : value
        },
        encryption(value) {
            return encodeURIComponent(CryptoJS.AES.encrypt(value, process.env.VUE_APP_FRONT_CODE).toString());
        },
        showDialogFunc(MachineID, itemNotes) {
            if (itemNotes == null) {
                itemNotes = []
            }
            this.$emit('showDialogFunc', MachineID, itemNotes)
        },
        showQRcodeFunc(VS) {
            this.$emit('showQRcodeFunc', VS)
        },
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    destroyed() {
        clearInterval(this.timer)
    }
}
</script>