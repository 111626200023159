<template>
    <v-snackbar v-model="snackbar" :color="mescolor" right :timeout="timeout">
        {{ message }}

        <!-- <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template> -->
    </v-snackbar>
</template>

<script>

export default {
    data() {
        return {
            timeout: -1,
            snackbar: false,
            mescolor: this.color
        }
    },
    props: {
        message: {
            type: String
        },
        color: {
            type: String,
            default: "error"
        },
        snackBarStatue: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        snackBarStatue(n) {
            this.snackbar = n
        },
        color(n) {
            console.log(n)
            this.mescolor = n
        }
    }
}

</script>