<template>
  <v-navigation-drawer v-model="showDrawer" height="100vh" dark absolute floating style="background-color:#4A67B4;">
    <v-list>

      <v-list-item link :to="'/accountView'">
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            {{ username }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link v-for="data in nav_bar_links" @click="closeNav" router :to="data.path" :key="data.title">
        <v-list-item-icon>
          <!-- <v-img :src="getImgUrl(data.icon)"></v-img> -->
          <SvgIcon type="mdi" :path="data.icon"></SvgIcon>
        </v-list-item-icon>
        <v-list-item-title class="text-h6 py-3">{{ data.title }}</v-list-item-title>
      </v-list-item>
    </v-list>


    <template v-slot:append>
      <v-list nav dense>
        <v-list-item link class="mx-0 px-0">
          <v-btn @click='logout()' :ripple="false" color="transparent" class="elevation-0">
            <v-list-item-icon>
              <SvgIcon type="mdi" :path="logoutIcon"></SvgIcon>
            </v-list-item-icon>
            <v-list-item-title class="text-h6">Logout</v-list-item-title>
          </v-btn>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import SvgIcon from '@jamescoyle/vue-icon'
/* mdiFormSelect,mdiMapMarkerMultiple, */
import { mdiViewDashboard, mdiViewList, mdiLogout, mdiCog, } from '@mdi/js';

export default {
  props: {
    show: { type: Boolean, required: true, default: false }
  },
  components: {
    SvgIcon
  },
  data() {
    return {
      showDrawer:false,
      username: this.$cookies.get('user-info').username,
      email: this.$cookies.get('user-info').email,
      logoutIcon: mdiLogout,
      nav_bar_links: [
        { title: "Monitoring", icon: mdiViewDashboard, path: "/monitoring" },
        { title: "historical Data", icon: mdiViewList, path: "/historicalList" },
        { title: "Setting", icon: mdiCog, path: "/setting" },
      ]
    }
  },
  methods: {
    getImgUrl(pic) {
      return require('../assets/' + pic)
    },
    async logout() {
      while (this.$cookies.isKey('user-info')) {
        this.$cookies.remove('user-info', "/")
        this.$cookies.remove('setting', "/")
      }
      this.$router.push({ name: 'dashboard' })

    },
  },
  watch: {
    show(nw) {
      this.showDrawer = nw
    }
  }
};


</script>