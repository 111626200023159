<template>
    <v-card dark :hover="data.hover" @mouseenter="hov = true"
        @mouseleave="hov = false"><!-- :class="{ nonActive: !data.isActive }" -->
        <v-container fluid>
            <v-row no-gutters v-if="view != 'vitalsign'">
                <v-col cols="3">
                    <v-sheet class="pa-1 mx-1 mb-1 rounded-lg text-center red darken-4 font-weight-medium" color="#546E7A"
                        v-if="data.ChiName == '' && data.LastName == ''">
                        No Entry
                    </v-sheet>
                    <v-sheet class="pa-1 mx-1 mb-1 rounded-lg text-center" color="#546E7A" v-else-if="data.ChiName != ''">
                        {{ data.ChiName }}
                    </v-sheet>
                    <v-sheet v-else class="pa-1 mx-1 mb-1 rounded-lg text-center" color="#546E7A">
                        {{ data.FirstName }}{{ data.LastName }}
                    </v-sheet>
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-sheet class="pa-1 rounded-lg" color="#546E7A" v-if="data.hover">
                        {{ data.PID == "" ? "No PID entry" : data.PID }}
                    </v-sheet>
                    <v-sheet class="pa-1 rounded-lg" color="#546E7A" v-else>
                        {{ data.PID == "" || data.PID == undefined ? "No PID entry" : data.PID }}
                    </v-sheet>
                </v-col>

                <v-col class="text-center">

                    <!--                     <v-carousel v-if="alarmData" continuous vertical cycle hide-delimiters :show-arrows="false" interval=1000
                        height="40">
                        <v-carousel-item v-for="(data,i) in alarmData" :key="i"> -->
                    <v-sheet class="mx-2 pa-1 rounded-lg black--text text-bold font-weight-bold" :color="showAL.priorty">
                        {{ showAL.AlarmType }}
                    </v-sheet>
                    <!-- <div v-for="(data, i) in alarmData" :key="i">
                        <v-sheet :class="{ 'd-none': controlAlarm(alarmData) }"
                            class="mx-2 pa-1 rounded-lg black--text text-bold font-weight-bold" :color="data.priorty">
                            {{ data.AlarmType }}
                        </v-sheet>
                    </div> -->
                    <!-- </v-carousel-item>
                    </v-carousel> -->
                    <!-- <v-sheet v-else class="pa-1 rounded-lg grey--text text-subtitle-2">
                        {{ data.MachineID == "" ? "Please enter MID" : data.MachineID }}
                    </v-sheet> -->
                </v-col>
                <!-- <v-col class="text-center">
                    <v-sheet class="ma-1 rounded-lg white--text text-subtitle-2" color="red">
                        ECG HR LOW > 100
                    </v-sheet>
                </v-col> -->

            </v-row>
            <!--  <v-expand-transition>
                                <div v-show="data.VSexpand"> -->
            <v-divider v-if="view == 'full' || view == 'collapse' || view == 'compulsory'"></v-divider>

            <v-row no-gutters v-if="view == 'full' || view == 'collapse' || view == 'vitalsign' || view == 'compulsory'">
                <v-col cols="7">
                    <!-- <v-sheet class="pa-1 rounded-lg" v-if="data.sys != ''">
                        <span class="nibpText text-caption">NIBP: <span class="text-h5 font-weight-bold">
                                {{ data.sys == "" ? "---" : data.sys }}/
                                {{ data.dia == "" ? "---" : data.dia }}
                                ({{ data.mean == "" ? "---" :
                                    data.mean }}) </span></span><span class="nibpText text-caption">PR: <span
                                class="text-h6 font-weight-bold">{{
                                    data.nibppr == "" ? "---" : data.nibppr
                                }}</span></span>
                    </v-sheet> -->
                    <v-sheet class="rounded-lg">
                        <span class="nibpText text-caption">NIBP: <span class="text-h5 font-weight-bold">
                                {{ data.previousNIBP.sys == "" ? "---" : data.previousNIBP.sys }}/
                                {{ data.previousNIBP.dia == "" ? "---" : data.previousNIBP.dia }}
                                ({{ data.previousNIBP.mean == "" ? "---" : data.previousNIBP.mean }})
                            </span></span>
                    </v-sheet>
                </v-col>
                <v-col>
                    <v-sheet class="pa-1 mx-1 mb-1 rounded-lg">
                        <span class="spo2Text text-caption ">SPO2:
                            <span class="text-h5 font-weight-bold"
                                :style="[alarming.spo2 ? { 'background-color': alarmFlash.spo2 } : { 'background-color': '' }]"
                                :class="{ 'alarmingParaText': alarming.spo2, 'spo2Text': !alarming.spo2 }">
                                {{ data.spo2 == "" ? "---" :
                                    data.spo2 }}
                            </span>
                        </span>

                    </v-sheet>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="view == 'full' || view == 'collapse' || view == 'vitalsign' || view == 'compulsory'">
                <v-col cols="4">
                    <v-sheet class="pa-1 rounded-lg">
                        <span class="ecghrText text-caption" v-if="data.hr != ''">ECG: <span
                                :style="[alarming.ecg ? { 'background-color': alarmFlash.ecg } : { 'background-color': '' }]"
                                class="text-h5 font-weight-bold"
                                :class="{ 'alarmingParaText': alarming.ecg, 'ecghrText': !alarming.ecg }">{{
                                    data.hr == "" ? "---" : data.hr
                                }}</span></span>
                        <span class="spo2Text text-caption" v-else-if="data.spo2pr != ''">PR:
                            <span class="text-h5 font-weight-bold">
                                {{ data.spo2pr == "" ? "---" : data.spo2pr }}
                            </span>
                        </span>
                        <span class="nibpText text-caption" v-else>PR:
                            <span class="text-h5 font-weight-bold shallowText">{{
                                data.previousNIBP.nibppr == "" ? "---"
                                : data.previousNIBP.nibppr }}</span></span>
                    </v-sheet>

                </v-col>
                <v-col class="text-center">
                    <v-sheet class="pa-1 mx-1 rounded-lg">
                        <span class="rrText text-caption">RR: <span class="text-h5 font-weight-bold">{{
                            data.rr == "" ? "---" : data.rr
                        }}</span></span>
                    </v-sheet>
                </v-col>
                <!-- <v-col cols="4" v-if="view == 'full'" class="text-center">
                    <v-sheet class="pa-1 mx-1 rounded-lg">
                        <span class="co2Text text-caption">etco2: <span class="text-h5 font-weight-bold">{{ data.etco2 ==
                            "" && parseIfNotNullUndefined(data.etco2)
                            ? "---" : data.etco2 }}</span></span>
                    </v-sheet>
                </v-col> -->
                <v-col><!-- v-if="view == 'compulsory'" -->
                    <v-sheet class="py-2 rounded-lg text-center" color="#37474F">
                        <v-row no-gutters>
                            <v-col class="ml-2" cols="2">
                                <v-img max-height="30px" max-width="30px" contain src="/deviceIcon/MwearIcon-white.png"></v-img>
                            </v-col>
                            <v-col>
                                <span class="white--text font-weight-bold">{{ zone.Loc }}</span>
                            </v-col>
                        </v-row>


                    </v-sheet>
                </v-col>
            </v-row>
            <!--             <v-row no-gutters>
                        <v-col v-if="device.includes('ecg')" class="mx-1" >
                            <vitalSignSinglePara :title="'ECG'" :alarming="alarmFlash.ecg" :styleChoose="'ecghrText'"
                                :unit="'bpm'" :vitalSignPara="data.hr" />
                        </v-col>
                        <v-col v-else>
                            <vitalSignSinglePara :title="'PR'" :alarming="alarmFlash.pr" :styleChoose="'spo2Text'"
                                :unit="'bpm'" :vitalSignPara="data.spo2pr" />
                        </v-col>
                        <v-col>
                            <vitalSignSinglePara :title="'SpO2'" :alarming="alarmFlash.spo2" :styleChoose="'spo2Text'"
                                :unit="'%'" :vitalSignPara="data.spo2" />
                        </v-col>
                        <v-col>
                            <vitalSignSinglePara :title="'RR'" :alarming="alarmFlash.rr" :styleChoose="'rrText'"
                                :unit="'rpm'" :vitalSignPara="data.rr" />
                        </v-col>
                        <v-col v-if="device.includes('nibp')">
                            <vitalSignSinglePara :title="'NIBP'" :alarming="alarmFlash.nibp" :styleChoose="'nibpText'"
                                :unit="'mmHg'" :vitalSignPara="data.previousNIBP.sys + '/' + data.previousNIBP.dia"
                                :subPara="data.previousNIBP.mean" />
                        </v-col>
                        <v-col v-else></v-col>
                    </v-row> -->
            <!--           <v-row no-gutters v-if="view == 'full'">
                <v-col>
                    <v-sheet class="pa-1 rounded-lg">
                        <span class="tempText text-caption">Temp: <span class="text-h5 font-weight-bold">{{
                            data.temp == "" ? "---" : data.temp
                        }}</span></span>
                    </v-sheet>
                </v-col>
                <v-col col="3">
                    <v-sheet class="py-2 rounded-lg text-center" color="#37474F">
                        <span class="white--text font-weight-bold">{{ zone.Loc }}</span>
                    </v-sheet>
                </v-col>
            </v-row> -->

            <v-row no-gutters v-if="view == 'full'">
                <v-col cols="12">
                    <v-chip v-for="(chip, index) in data.notes" :key="index" :color="chip.color" class="mx-1 mb-1"
                        @click="removeChip(index)">{{
                            chip.text
                        }}</v-chip>
                </v-col>
            </v-row>
            <!-- </div>
                            </v-expand-transition> -->

            <v-row no-gutters v-if="hov && mode && (view == 'full' || view == 'compulsory')">
                <v-card-actions>
                    <v-btn router link
                        :to="'/chartView/' + encryption(data.MachineID) + '/' + encryption('') + '/' + encryption('') + '/' + encryption('now')"
                        :disabled="data.MAC === ''">chart</v-btn>
                    <!--                     <v-btn @click="showDialogFunc(data.MachineID, data.notes)" :disabled="data.MachineID === ''">Add
                        notes</v-btn> -->
                    <v-btn @click="showQRcodeFunc(data)" :disabled="data.bed === ''">
                        <SvgIcon type="mdi" :path="mdiQrcode"></SvgIcon>
                    </v-btn>
                </v-card-actions>
            </v-row>



        </v-container>
    </v-card>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiQrcode, } from '@mdi/js';
import CryptoJS from "crypto-js"
export default {
    name: 'vitalSignCard',
    components: {
        SvgIcon,
    },
    data() {
        return {
            data: this.vitalSign,
            alarmData: this.alarm,
            showAL: {},
            showIndex: 0,
            mdiQrcode: mdiQrcode,
            hov: false,
            timer: null,
            i: 0,
            interact: false,
            zone: this.Zone,
            alarmFlash: {
                ecg: 'dark',
                spo2: 'dark',
                nibp: 'dark',
                rr: 'dark',
                pr: 'dark',
            },
            alarming: {
                ecg: false,
                spo2: false,
                nibp: false,
                rr: false,
                pr: false,
            },

            altimer: null
        }
    },
    props: {
        vitalSign: {
            type: Object,

        },
        alarm: {
            type: Array,
        },
        mode: {
            type: Boolean,
            default: true
        },
        Zone: {
            type: Object,

        },
        view: {
            type: String,
        },
    },
    watch: {
        vitalSign: {
            handler: function (n) {
                if (n != undefined && n != null) {
                    this.data = n
                }
            },
            deep: true
        },
        alarm: function (n) {
            this.alarmData = n
        },
        mode: function (n) {
            if (n != undefined && n != null) {
                this.interact = n
            }
        },
        Zone: function (n) {
            if (n != undefined && n != null) {
                this.zone = n
            }
        },
    },
    methods: {
        parseIfNotNullUndefined(value) {
            return value == null || value == undefined ? false : true
        },
        returnNull(value) {
            return value == "" ? null : parseInt(value)
        },
        returnNullF(value) {
            return value == "" ? null : "<<" + parseFloat(value).toFixed(1) + ">>"
        },
        returnEmpty(value) {
            return value == "" ? "---" : value
        },
        encryption(value) {
            return encodeURIComponent(CryptoJS.AES.encrypt(value, process.env.VUE_APP_FRONT_CODE).toString());
        },
        showDialogFunc(MachineID, itemNotes) {
            if (itemNotes == null) {
                itemNotes = []
            }
            this.$emit('showDialogFunc', MachineID, itemNotes)
        },
        showQRcodeFunc(VS) {
            this.$emit('showQRcodeFunc', VS)
        },
    },
    created() {
        if (this.alarmData.length > 0) {
            if (this.alarmData.length > 1) {
                this.showAL = this.alarmData[1]
                setTimeout(() => {
                    this.showAL = {}
                }, 1400)
            } else {
                this.showAL = this.alarmData[0]
            }
            this.alarmData.forEach((e) => {
                if (e.related == 'ECG') {
                    this.alarmFlash.ecg = e.priorty
                    this.alarming.ecg = true
                }
                if (e.related == 'SPO2') {
                    this.alarmFlash.spo2 = e.priorty
                    this.alarming.spo2 = true
                }
            })
        }

    },
    async mounted() {
        this.timer = setInterval(() => {
            if (this.alarmData.length > 0) {
                if (this.alarmData.length > 1) {
                    this.showAL = this.alarmData[this.showIndex % this.alarmData.length]
                    setTimeout(() => {
                        this.showAL = {}
                    }, 1400)
                } else {
                    this.showAL = this.alarmData[0]
                }
            }

            this.showIndex++

        }, 1500)
        this.altimer = setInterval(() => {
            if (this.alarmFlash.ecg != 'dark') {
                if (this.alarming.ecg == true) {
                    this.alarming.ecg = false
                } else {
                    this.alarming.ecg = true
                }
            }
            if (this.alarmFlash.spo2 != 'dark') {
                if (this.alarming.spo2 == true) {
                    this.alarming.spo2 = false
                } else {
                    this.alarming.spo2 = true
                }
            }
        }, 500)

    },
    beforeDestroy() {
        clearInterval(this.timer)
        clearInterval(this.altimer)
    },
    destroyed() {
        clearInterval(this.timer)
        clearInterval(this.altimer)
    }
}
</script>