import Vue from 'vue'
/* import LoginView from '../views/LoginView.vue'
import RegristerView from '../views/RegisterView.vue' */
/* import dashboardNFView from '../views/monitoring/deviceDashboardWSNF.vue' */
/* import historicalDataView from '../views/historicalDataView.vue' */
/* import dashboardLogView from '../views/dashboardMView.vue' */
import chartingView from '../views/ChartingView.vue'
/* import accountView from '../views/AccountView.vue'
import settingView from '../views/settingView.vue'
import continousView from '../views/continousView.vue' */
/* import LocationView from '../views/LocationSystem.vue' */
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
/* import dashboardView from '../views/alertDashboard/alertDashboard.vue' */
import vitalSignDashboardView from '../views/monitoring/deviceDashboardWS'
import dashboardHKTActiveAlarm from '../views/monitoring/deviceDashboardActiveAlarm'

Vue.use(VueCookies);
Vue.use(VueRouter)

const routes = [
/*   {
    path: '/',
    name: 'dashboard',
    component: dashboardView
  },
  {
    path: '/Login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegristerView
  },
  {
    path: '/historicalList/:id?',
    name: 'historicalList',
    component: historicalDataView,
    props: true
  }, */
  {
    path: '/chartView/:MAC/:HN/:BED/:DATE',
    name: 'chartView',
    component: chartingView,
    props: true
  },
/*   {
    path: '/accountView',
    name: 'accountView',
    component: accountView,
  },
  {
    path: '/setting',
    name: 'settingView',
    component: settingView,
  }, */
  /* 
    {
      path: '/location',
      name: 'LocationView',
      component: LocationView,
    }, */
/*   {
    path: '/monitoring',
    name: 'continousView',
    component: continousView,
  }, */
  {
    path: '/dashboard',
    name: 'dashboardHKT',
    component: vitalSignDashboardView,
    props: route => (
      { DN: route.query.DN, 
        AE: route.query.AE,
        alarmfil: route.query.alarmfil,//n,m,h
        view:route.query.view,//full,collapse,min,vitalsign,compulsory
      })
  },
  {
    path: '/dashboardActiveAlarm',
    name: 'dashboardHKTActiveAlarm',
    component: dashboardHKTActiveAlarm,
    props: route => (
      { DN: route.query.DN, 
        AE: route.query.AE,
        alarmfil: route.query.alarmfil,//n,m,h
        view:route.query.view,//full,collapse,min,vitalsign,compulsory
      })
  },
]



const router = new VueRouter({
  mode: "history",
  routes
})

/* router.beforeEach((to, from, next) => {
  let isAuthenticated = window.$cookies.get('user-info')
  if (to.name === "dashboardHKT") {
    next();
  } else {
    if (isAuthenticated != null && (to.name === 'login' || to.name === 'dashboard')) {
      next({ name: 'continousView' })
    }
    if (to.name !== 'login' && to.name !== 'dashboard' && to.name !== 'registration' && isAuthenticated == null) {
      next({ name: 'login' });
    } else {
      next();
    }
  }

}) */

export default router
