<template>
  <v-app>

    <appBar :login="!login" v-if="!iframeAPP"/>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    show: false
  }),
};
</script>

<script>
  import appBar from './components/appBar.vue'
  export default{
     components:{
      appBar
     },
     data:()=>({}),
     computed: {
            login(){
              return this.$route.name !== 'login'&& this.$route.name!== 'dashboard'&& this.$route.name!== 'registration'
            },
            iframeAPP(){
              return this.$route.name == 'dashboardHKT'|| this.$route.name !== 'charting'
            }
      }
  };

</script>

<style scoped>
body {
  background-image: url('./assets/Launch_blur.jpg');
  background-size: cover, contain;
  background-repeat: repeat-y;
}
</style>
